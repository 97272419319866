import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import InfoCard from "../../components/InfoCard";
import UserIcon from "@material-ui/icons/PeopleAltTwoTone";
import SubscriptionIcon from '@material-ui/icons/CardMembershipTwoTone';
import RevenueIcon from "@material-ui/icons/MonetizationOnTwoTone";
import PostIcon from "@material-ui/icons/ImageTwoTone";
import SmartTable from "../../components/Common/SmartTable/tabularTable";
import { numberWithCommas } from "../../contants";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../../redux/action/utilActions";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
}));

const DashComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { userCount, subscriptionCount, revenue, userSubscription } = useSelector(state => state.util.dashboard)
  console.log('userSubscription', userSubscription);
  const INfoArray = [
    {
      title: "Total Active Users",
      icon: UserIcon,
      count: userCount || 0,
    },
    {
      title: "Total Subscriptions",
      icon: SubscriptionIcon,
      count: subscriptionCount || 0,
    },
    {
      title: "Total Revenue",
      icon: RevenueIcon,
      count: revenue || 0,
      currency: true,
    },
  ];

  const tableHeaders = [
    { title: "Transaction Id", key: "orderId" },
    { title: "Mobile", key: "mobile" },
    { title: "email", key: "email" },
    { title: "Amount", key: "amount" },
    { title: "Payment By", key: "paymentId" },
    { title: "Payment Method", key: "method" },
    { title: "Status", key: "paymentStatus" },
  ];

  React.useEffect(() => {
    dispatch(getDashboard())
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {INfoArray.map((item, index) => {
          return (
            <InfoCard
              key={item.title}
              title={item.title}
              icon={item.icon}
              currency={item.currency}
              count={numberWithCommas(item.count)}
            />
          );
        })}
        <Grid item lg={12}>
          <SmartTable
            tableTitle={"Transactions"}
            tableData={userSubscription}
            header={tableHeaders}
            sortable={true}
            paginated={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default DashComponent;
