export const utilTypes = {
  GET_DASHBOARD: "GET_DASHBOARD",
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_FAIL: "GET_DASHBOARD_FAIL",

  SET_PAGE: "SET_PAGE",
  SET_ROWS_PAGE: "SET_ROWS_PAGE",
};

export const authTypes = {
  LOGIN_USER: "LOGIN_USER",
  DELETE_USER_ACCOUNT: "DELETE_USER_ACCOUNT",

  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  LOGOUT_USER: "LOGOUT_USER",
};

export const userTypes = {
  GET_USER_DETAILS: "GET_USER_DETAILS",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAIL: "GET_USER_DETAILS_FAIL",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",

  GET_APP_USERS: "GET_APP_USERS",
  GET_APP_USERS_SUCCESS: "GET_APP_USERS_SUCCESS",
  GET_APP_USERS_FAIL: "GET_APP_USERS_FAIL",

  GET_ADMIN_USERS: "GET_ADMIN_USERS",
  GET_ADMIN_USERS_SUCCESS: "GET_ADMIN_USERS_SUCCESS",
  GET_ADMIN_USERS_FAIL: "GET_ADMIN_USERS_FAIL",

  GET_SUBSCRIBED_USERS: "GET_SUBSCRIBED_USERS",
  GET_SUBSCRIBED_USERS_SUCCESS: "GET_SUBSCRIBED_USERS_SUCCESS",
  GET_SUBSCRIBED_USERS_FAIL: "GET_SUBSCRIBED_USERS_FAIL",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAIL: "DELETE_USER_FAIL",

  GET_BUSINESS_DETAILS: "GET_BUSINESS_DETAILS",
  GET_BUSINESS_DETAILS_SUCCESS: "GET_BUSINESS_DETAILS_SUCCESS",
  GET_BUSINESS_DETAILS_FAIL: "GET_BUSINESS_DETAILS_FAIL",

  CREATE_BUSINESS: "CREATE_BUSINESS",
  CREATE_BUSINESS_SUCCESS: "CREATE_BUSINESS_SUCCESS",
  CREATE_BUSINESS_FAIL: "CREATE_BUSINESS_FAIL",

  DELETE_BUSINESS: "DELETE_BUSINESS",
  DELETE_BUSINESS_SUCCESS: "DELETE_BUSINESS_SUCCESS",
  DELETE_BUSINESS_FAIL: "DELETE_BUSINESS_FAIL",

  UPDATE_BUSINESS: "UPDATE_BUSINESS",
  UPDATE_BUSINESS_SUCCESS: "UPDATE_BUSINESS_SUCCESS",
  UPDATE_BUSINESS_FAIL: "UPDATE_BUSINESS_FAIL",

  ASSIGN_SUBSCRIPTION: "ASSIGN_SUBSCRIPTION",
  ASSIGN_SUBSCRIPTION_SUCCESS: "ASSIGN_SUBSCRIPTION_SUCCESS",
  ASSIGN_SUBSCRIPTION_FAIL: "ASSIGN_SUBSCRIPTION_FAIL",

  DELETE_SUBSCRIPTION: "DELETE_SUBSCRIPTION",
  DELETE_SUBSCRIPTION_SUCCESS: "DELETE_SUBSCRIPTION_SUCCESS",
  DELETE_SUBSCRIPTION_FAIL: "DELETE_SUBSCRIPTION_FAIL",

  ADD_BUSINESS: "ADD_BUSINESS",
  ADD_BUSINESS_SUCCESS: "ADD_BUSINESS_SUCCESS",
  ADD_BUSINESS_FAIL: "ADD_BUSINESS_FAIL",

  ADD_GRAMPANCHAYAT: "ADD_GRAMPANCHAYAT",
  ADD_GRAMPANCHAYAT_SUCCESS: "ADD_GRAMPANCHAYAT_SUCCESS",
  ADD_GRAMPANCHAYAT_FAIL: "ADD_GRAMPANCHAYAT_FAIL",

  ADD_ELECTION: "ADD_ELECTION",
  ADD_ELECTION_SUCCESS: "ADD_ELECTION_SUCCESS",
  ADD_ELECTION_FAIL: "ADD_ELECTION_FAIL",
};

export const categoryTypes = {
  GET_CATEGORIES_DETAILS: "GET_CATEGORIES_DETAILS",
  GET_CATEGORIES_DETAILS_SUCCESS: "GET_CATEGORIES_DETAILS_SUCCESS",
  GET_CATEGORIES_DETAILS_FAIL: "GET_CATEGORIES_DETAILS_FAIL",

  CREATE_CATEGORIES: "CREATE_CATEGORIES",
  CREATE_CATEGORIES_SUCCESS: "CREATE_CATEGORIES_SUCCESS",
  CREATE_CATEGORIES_FAIL: "CREATE_CATEGORIES_FAIL",

  UPDATE_CATEGORIES: "UPDATE_CATEGORIES",
  UPDATE_CATEGORIES_SUCCESS: "UPDATE_CATEGORIES_SUCCESS",
  UPDATE_CATEGORIES_FAIL: "UPDATE_CATEGORIES_FAIL",

  GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAIL: "GET_ALL_CATEGORIES_FAIL",

  DELETE_CATEGORIES: "DELETE_CATEGORIES",
  DELETE_CATEGORIES_SUCCESS: "DELETE_CATEGORIES_SUCCESS",
  DELETE_CATEGORIES_FAIL: "DELETE_CATEGORIES_FAIL",
};

export const festivalTypes = {
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_FAIL: "SEND_NOTIFICATION_FAIL",

  GET_FESTIVALS_DETAILS: "GET_FESTIVALS_DETAILS",
  GET_FESTIVALS_DETAILS_SUCCESS: "GET_FESTIVALS_DETAILS_SUCCESS",
  GET_FESTIVALS_DETAILS_FAIL: "GET_FESTIVALS_DETAILS_FAIL",

  CREATE_FESTIVALS: "CREATE_FESTIVALS",
  CREATE_FESTIVALS_SUCCESS: "CREATE_FESTIVALS_SUCCESS",
  CREATE_FESTIVALS_FAIL: "CREATE_FESTIVALS_FAIL",

  UPDATE_FESTIVALS: "UPDATE_FESTIVALS",
  UPDATE_FESTIVALS_SUCCESS: "UPDATE_FESTIVALS_SUCCESS",
  UPDATE_FESTIVALS_FAIL: "UPDATE_FESTIVALS_FAIL",

  GET_ALL_FESTIVALS: "GET_ALL_FESTIVALS",
  GET_ALL_FESTIVALS_SUCCESS: "GET_ALL_FESTIVALS_SUCCESS",
  GET_ALL_FESTIVALS_FAIL: "GET_ALL_FESTIVALS_FAIL",

  DELETE_FESTIVALS: "DELETE_FESTIVALS",
  DELETE_FESTIVALS_SUCCESS: "DELETE_FESTIVALS_SUCCESS",
  DELETE_FESTIVALS_FAIL: "DELETE_FESTIVALS_FAIL",
};

export const subcategoryTypes = {
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_FAIL: "SEND_NOTIFICATION_FAIL",

  GET_SUBCATEGORIES_DETAILS: "GET_SUBCATEGORIES_DETAILS",
  GET_SUBCATEGORIES_DETAILS_SUCCESS: "GET_SUBCATEGORIES_DETAILS_SUCCESS",
  GET_SUBCATEGORIES_DETAILS_FAIL: "GET_SUBCATEGORIES_DETAILS_FAIL",

  CREATE_SUBCATEGORIES: "CREATE_SUBCATEGORIES",
  CREATE_SUBCATEGORIES_SUCCESS: "CREATE_SUBCATEGORIES_SUCCESS",
  CREATE_SUBCATEGORIES_FAIL: "CREATE_SUBCATEGORIES_FAIL",

  UPDATE_SUBCATEGORIES: "UPDATE_SUBCATEGORIES",
  UPDATE_SUBCATEGORIES_SUCCESS: "UPDATE_SUBCATEGORIES_SUCCESS",
  UPDATE_SUBCATEGORIES_FAIL: "UPDATE_SUBCATEGORIES_FAIL",

  GET_SUBCATEGOEY_IMAGES: "GET_SUBCATEGOEY_IMAGES",
  GET_SUBCATEGOEY_IMAGES_SUCCESS: "GET_SUBCATEGOEY_IMAGES_SUCCESS",
  GET_SUBCATEGOEY_IMAGES_FAIL: "GET_SUBCATEGOEY_IMAGES_FAIL",

  UPLOAD_SUBCATEGORY_IMAGES: "UPLOAD_SUBCATEGORY_IMAGES",
  UPLOAD_SUBCATEGORY_IMAGES_SUCCESS: "UPLOAD_SUBCATEGORY_IMAGES_SUCCESS",
  UPLOAD_SUBCATEGORY_IMAGES_FAIL: "UPLOAD_SUBCATEGORY_IMAGES_FAIL",

  GET_ALL_SUBCATEGORIES: "GET_ALL_SUBCATEGORIES",
  GET_ALL_SUBCATEGORIES_SUCCESS: "GET_ALL_SUBCATEGORIES_SUCCESS",
  GET_ALL_SUBCATEGORIES_FAIL: "GET_ALL_SUBCATEGORIES_FAIL",

  DELETE_SUBCATEGORIES: "DELETE_SUBCATEGORIES",
  DELETE_SUBCATEGORIES_SUCCESS: "DELETE_SUBCATEGORIES_SUCCESS",
  DELETE_SUBCATEGORIES_FAIL: "DELETE_SUBCATEGORIES_FAIL",
};

export const customcategoryTypes = {
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_FAIL: "SEND_NOTIFICATION_FAIL",

  GET_CUSTOMCATEGORIES_DETAILS: "GET_CUSTOMCATEGORIES_DETAILS",
  GET_CUSTOMCATEGORIES_DETAILS_SUCCESS: "GET_CUSTOMCATEGORIES_DETAILS_SUCCESS",
  GET_CUSTOMCATEGORIES_DETAILS_FAIL: "GET_CUSTOMCATEGORIES_DETAILS_FAIL",

  CREATE_CUSTOMCATEGORIES: "CREATE_CUSTOMCATEGORIES",
  CREATE_CUSTOMCATEGORIES_SUCCESS: "CREATE_CUSTOMCATEGORIES_SUCCESS",
  CREATE_CUSTOMCATEGORIES_FAIL: "CREATE_CUSTOMCATEGORIES_FAIL",

  UPDATE_CUSTOMCATEGORIES: "UPDATE_CUSTOMCATEGORIES",
  UPDATE_CUSTOMCATEGORIES_SUCCESS: "UPDATE_CUSTOMCATEGORIES_SUCCESS",
  UPDATE_CUSTOMCATEGORIES_FAIL: "UPDATE_CUSTOMCATEGORIES_FAIL",

  GET_CUSTOMCATEGOEY_IMAGES: "GET_CUSTOMCATEGOEY_IMAGES",
  GET_CUSTOMCATEGOEY_IMAGES_SUCCESS: "GET_CUSTOMCATEGOEY_IMAGES_SUCCESS",
  GET_CUSTOMCATEGOEY_IMAGES_FAIL: "GET_CUSTOMCATEGOEY_IMAGES_FAIL",

  UPLOAD_CUSTOMCATEGORY_IMAGES: "UPLOAD_CUSTOMCATEGORY_IMAGES",
  UPLOAD_CUSTOMCATEGORY_IMAGES_SUCCESS: "UPLOAD_CUSTOMCATEGORY_IMAGES_SUCCESS",
  UPLOAD_CUSTOMCATEGORY_IMAGES_FAIL: "UPLOAD_CUSTOMCATEGORY_IMAGES_FAIL",

  GET_ALL_CUSTOMCATEGORIES: "GET_ALL_CUSTOMCATEGORIES",
  GET_ALL_CUSTOMCATEGORIES_SUCCESS: "GET_ALL_CUSTOMCATEGORIES_SUCCESS",
  GET_ALL_CUSTOMCATEGORIES_FAIL: "GET_ALL_CUSTOMCATEGORIES_FAIL",

  DELETE_CUSTOMCATEGORIES: "DELETE_CUSTOMCATEGORIES",
  DELETE_CUSTOMCATEGORIES_SUCCESS: "DELETE_CUSTOMCATEGORIES_SUCCESS",
  DELETE_CUSTOMCATEGORIES_FAIL: "DELETE_CUSTOMCATEGORIES_FAIL",
};

export const greetingTypes = {
  GET_GREETINGS_DETAILS: "GET_GREETINGS_DETAILS",
  GET_GREETINGS_DETAILS_SUCCESS: "GET_GREETINGS_DETAILS_SUCCESS",
  GET_GREETINGS_DETAILS_FAIL: "GET_GREETINGS_DETAILS_FAIL",

  CREATE_GREETINGS: "CREATE_GREETINGS",
  CREATE_GREETINGS_SUCCESS: "CREATE_GREETINGS_SUCCESS",
  CREATE_GREETINGS_FAIL: "CREATE_GREETINGS_FAIL",

  UPDATE_GREETINGS: "UPDATE_GREETINGS",
  UPDATE_GREETINGS_SUCCESS: "UPDATE_GREETINGS_SUCCESS",
  UPDATE_GREETINGS_FAIL: "UPDATE_GREETINGS_FAIL",

  GET_GREETING_IMAGES: "GET_GREETING_IMAGES",
  GET_GREETING_IMAGES_SUCCESS: "GET_GREETING_IMAGES_SUCCESS",
  GET_GREETING_IMAGES_FAIL: "GET_GREETING_IMAGES_FAIL",

  UPLOAD_GREETING_IMAGES: "UPLOAD_GREETING_IMAGES",
  UPLOAD_GREETING_IMAGES_SUCCESS: "UPLOAD_GREETING_IMAGES_SUCCESS",
  UPLOAD_GREETING_IMAGES_FAIL: "UPLOAD_GREETING_IMAGES_FAIL",

  GET_ALL_GREETINGS: "GET_ALL_GREETINGS",
  GET_ALL_GREETINGS_SUCCESS: "GET_ALL_GREETINGS_SUCCESS",
  GET_ALL_GREETINGS_FAIL: "GET_ALL_GREETINGS_FAIL",

  DELETE_GREETINGS: "DELETE_GREETINGS",
  DELETE_GREETINGS_SUCCESS: "DELETE_GREETINGS_SUCCESS",
  DELETE_GREETINGS_FAIL: "DELETE_GREETINGS_FAIL",
};

export const postTypes = {
  GET_POSTS_DETAILS: "GET_POSTS_DETAILS",
  GET_POSTS_DETAILS_SUCCESS: "GET_POSTS_DETAILS_SUCCESS",
  GET_POSTS_DETAILS_FAIL: "GET_POSTS_DETAILS_FAIL",

  CREATE_POSTS: "CREATE_POSTS",
  CREATE_POSTS_SUCCESS: "CREATE_POSTS_SUCCESS",
  CREATE_POSTS_FAIL: "CREATE_POSTS_FAIL",

  UPDATE_POSTS: "UPDATE_POSTS",
  UPDATE_POSTS_SUCCESS: "UPDATE_POSTS_SUCCESS",
  UPDATE_POSTS_FAIL: "UPDATE_POSTS_FAIL",

  GET_ALL_POSTS: "GET_ALL_POSTS",
  GET_ALL_POSTS_SUCCESS: "GET_ALL_POSTS_SUCCESS",
  GET_ALL_POSTS_FAIL: "GET_ALL_POSTS_FAIL",

  DELETE_POSTS: "DELETE_POSTS",
  DELETE_POSTS_SUCCESS: "DELETE_POSTS_SUCCESS",
  DELETE_POSTS_FAIL: "DELETE_POSTS_FAIL",

  GET_FESTIVAL_POST: "GET_FESTIVAL_POST",
  GET_FESTIVAL_POST_SUCCESS: "GET_FESTIVAL_POST_SUCCESS",
  GET_FESTIVAL_POST_FAIL: "GET_FESTIVAL_POST_FAIL",

  CREATE_FESTIVAL_POSTS: "CREATE_FESTIVAL_POSTS",
  CREATE_FESTIVAL_POSTS_SUCCESS: "CREATE_FESTIVAL_POSTS_SUCCESS",
  CREATE_FESTIVAL_POSTS_FAIL: "CREATE_FESTIVAL_POSTS_FAIL",

  UPDATE_FESTIVAL_POSTS: "UPDATE_FESTIVAL_POSTS",
  UPDATE_FESTIVAL_POSTS_SUCCESS: "UPDATE_FESTIVAL_POSTS_SUCCESS",
  UPDATE_FESTIVAL_POSTS_FAIL: "UPDATE_FESTIVAL_POSTS_FAIL",

  DELETE_FESTIVAL_POSTS: "DELETE_FESTIVAL_POSTS",
  DELETE_FESTIVAL_POSTS_SUCCESS: "DELETE_FESTIVAL_POSTS_SUCCESS",
  DELETE_FESTIVAL_POSTS_FAIL: "DELETE_FESTIVAL_POSTS_FAIL",
};

export const frameTypes = {
  UPLOAD_FRAMES: "UPLOAD_FRAMES",
  UPLOAD_FRAMES_SUCCESS: "UPLOAD_FRAMES_SUCCESS",
  UPLOAD_FRAMES_FAIL: "UPLOAD_FRAMES_FAIL",

  CREATE_FRAMES: "CREATE_FRAMES",
  CREATE_FRAMES_SUCCESS: "CREATE_FRAMES_SUCCESS",
  CREATE_FRAMES_FAIL: "CREATE_FRAMES_FAIL",

  UPDATE_FRAMES: "UPDATE_FRAMES",
  UPDATE_FRAMES_SUCCESS: "UPDATE_FRAMES_SUCCESS",
  UPDATE_FRAMES_FAIL: "UPDATE_FRAMES_FAIL",

  GET_ALL_FRAMES: "GET_ALL_FRAMES",
  GET_ALL_FRAMES_SUCCESS: "GET_ALL_FRAMES_SUCCESS",
  GET_ALL_FRAMES_FAIL: "GET_ALL_FRAMES_FAIL",

  DELETE_FRAMES: "DELETE_FRAMES",
  DELETE_FRAMES_SUCCESS: "DELETE_FRAMES_SUCCESS",
  DELETE_FRAMES_FAIL: "DELETE_FRAMES_FAIL",
};

export const frameFieldsTypes = {
  CREATE_FRAME_FIELDS: "CREATE_FRAME_FIELDS",
  CREATE_FRAME_FIELDS_SUCCESS: "CREATE_FRAME_FIELDS_SUCCESS",
  CREATE_FRAME_FIELDS_FAIL: "CREATE_FRAME_FIELDS_FAIL",

  UPDATE_FRAME_FIELDS: "UPDATE_FRAME_FIELDS",
  UPDATE_FRAME_FIELDS_SUCCESS: "UPDATE_FRAME_FIELDS_SUCCESS",
  UPDATE_FRAME_FIELDS_FAIL: "UPDATE_FRAME_FIELDS_FAIL",

  GET_ALL_FRAME_FIELDS: "GET_ALL_FRAME_FIELDS",
  GET_ALL_FRAME_FIELDS_SUCCESS: "GET_ALL_FRAME_FIELDS_SUCCESS",
  GET_ALL_FRAME_FIELDS_FAIL: "GET_ALL_FRAME_FIELDS_FAIL",

  DELETE_FRAME_FIELDS: "DELETE_FRAME_FIELDS",
  DELETE_FRAME_FIELDS_SUCCESS: "DELETE_FRAME_FIELDS_SUCCESS",
  DELETE_FRAME_FIELDS_FAIL: "DELETE_FRAME_FIELDS_FAIL",
};

export const subscriptionTypes = {
  CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
  CREATE_SUBSCRIPTION_SUCCESS: "CREATE_SUBSCRIPTION_SUCCESS",
  CREATE_SUBSCRIPTION_FAIL: "CREATE_SUBSCRIPTION_FAIL",

  UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION_SUCCESS: "UPDATE_SUBSCRIPTION_SUCCESS",
  UPDATE_SUBSCRIPTION_FAIL: "UPDATE_SUBSCRIPTION_FAIL",

  GET_ALL_SUBSCRIPTION: "GET_ALL_SUBSCRIPTION",
  GET_ALL_SUBSCRIPTION_SUCCESS: "GET_ALL_SUBSCRIPTION_SUCCESS",
  GET_ALL_SUBSCRIPTION_FAIL: "GET_ALL_SUBSCRIPTION_FAIL",

  DELETE_SUBSCRIPTION: "DELETE_SUBSCRIPTION",
  DELETE_SUBSCRIPTION_SUCCESS: "DELETE_SUBSCRIPTION_SUCCESS",
  DELETE_SUBSCRIPTION_FAIL: "DELETE_SUBSCRIPTION_FAIL",
};

export const sliderTypes = {
  GET_SLIDERS_DETAILS: "GET_SLIDERS_DETAILS",
  GET_SLIDERS_DETAILS_SUCCESS: "GET_SLIDERS_DETAILS_SUCCESS",
  GET_SLIDERS_DETAILS_FAIL: "GET_SLIDERS_DETAILS_FAIL",

  CREATE_SLIDERS: "CREATE_SLIDERS",
  CREATE_SLIDERS_SUCCESS: "CREATE_SLIDERS_SUCCESS",
  CREATE_SLIDERS_FAIL: "CREATE_SLIDERS_FAIL",

  UPDATE_SLIDERS: "UPDATE_SLIDERS",
  UPDATE_SLIDERS_SUCCESS: "UPDATE_SLIDERS_SUCCESS",
  UPDATE_SLIDERS_FAIL: "UPDATE_SLIDERS_FAIL",

  GET_ALL_SLIDERS: "GET_ALL_SLIDERS",
  GET_ALL_SLIDERS_SUCCESS: "GET_ALL_SLIDERS_SUCCESS",
  GET_ALL_SLIDERS_FAIL: "GET_ALL_SLIDERS_FAIL",

  DELETE_SLIDERS: "DELETE_SLIDERS",
  DELETE_SLIDERS_SUCCESS: "DELETE_SLIDERS_SUCCESS",
  DELETE_SLIDERS_FAIL: "DELETE_SLIDERS_FAIL",
};

export const themeSampleTypes = {
  GET_THEME_SAMPLES_DETAILS: "GET_THEME_SAMPLES_DETAILS",
  GET_THEME_SAMPLES_DETAILS_SUCCESS: "GET_THEME_SAMPLES_DETAILS_SUCCESS",
  GET_THEME_SAMPLES_DETAILS_FAIL: "GET_THEME_SAMPLES_DETAILS_FAIL",

  CREATE_THEME_SAMPLES: "CREATE_THEME_SAMPLES",
  CREATE_THEME_SAMPLES_SUCCESS: "CREATE_THEME_SAMPLES_SUCCESS",
  CREATE_THEME_SAMPLES_FAIL: "CREATE_THEME_SAMPLES_FAIL",

  UPDATE_THEME_SAMPLES: "UPDATE_THEME_SAMPLES",
  UPDATE_THEME_SAMPLES_SUCCESS: "UPDATE_THEME_SAMPLES_SUCCESS",
  UPDATE_THEME_SAMPLES_FAIL: "UPDATE_THEME_SAMPLES_FAIL",

  GET_ALL_THEME_SAMPLES: "GET_ALL_THEME_SAMPLES",
  GET_ALL_THEME_SAMPLES_SUCCESS: "GET_ALL_THEME_SAMPLES_SUCCESS",
  GET_ALL_THEME_SAMPLES_FAIL: "GET_ALL_THEME_SAMPLES_FAIL",

  DELETE_THEME_SAMPLES: "DELETE_THEME_SAMPLES",
  DELETE_THEME_SAMPLES_SUCCESS: "DELETE_THEME_SAMPLES_SUCCESS",
  DELETE_THEME_SAMPLES_FAIL: "DELETE_THEME_SAMPLES_FAIL",
};

export const loadingTypes = {
  SET_LOADING: "SET_LOADING",

  CLEAR_LOADING: "CLEAR_LOADING",
};
