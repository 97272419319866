import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import BusinessIcon from "@material-ui/icons/BusinessCenterTwoTone";
import CommonAction from "../../components/Common/Actions/CommonAction";
import AssignSubscriptionIcon from "@material-ui/icons/CardMembershipTwoTone";
import {
  getAppUsers,
  createUser,
  deleteUser,
  updateUser,
  assignSubscription,
  submitBusinessDetails,
  submitPanchayatDetails,
  submitElectionDetails,
  deleteSubscription,
} from "../../redux/action/userActions";
import { useHistory } from "react-router";
import CommonActionModal from "../../components/Common/Modals/CommonActionModal";
import AssignSubscriptionModal from "../../components/Common/Modals/AssingSubscriptionModal";
import { getAllSubscriptions } from "../../redux/action/subscriptionActions";
import ElectionIcon from "@material-ui/icons/HowToVoteTwoTone";
import PanchayatIcon from "@material-ui/icons/AccountBalanceTwoTone";
import { userTypes } from "../../redux/types";
import loadingSelector from "../../redux/selectors/loadingSelector";

const AppUsers = () => {
  const { users: data, totalLength } = useSelector((state) => state.user);
  const { page, rowsPerPage } = useSelector((state) => state.util);
  const isLoading = useSelector((state) =>
    loadingSelector(userTypes.GET_APP_USERS, state)
  );
  const pagination = { page: page, limit: rowsPerPage };
  const [open, setOpen] = React.useState();
  const [assignData, setAssignData] = React.useState();
  const dispatch = useDispatch();

  // const CommonActionBtn = (action) => (
  //   (action.data.userType === 'business' || action.data.userType === 'panchayat') && <CommonAction
  //     onClick={() => { history.push(`/business/${action.data._id}`) }}
  //     title="View Busineeses"
  //     color="secondary"
  //     children={<Badge badgeContent={action.data.totalBusinesses} color="secondary">
  //       <BusinessIcon />
  //     </Badge>}
  //   />
  // );

  const formData = [
    {
      type: "text",
      name: "name",
      label: "Name",
      placeholder: "Type Name",
      size: 12,
    },
    {
      type: "text",
      name: "pin",
      label: "pin",
      placeholder: "Type pin",
      size: 12,
    },
    {
      type: "number",
      name: "mobile",
      label: "Mobile",
      placeholder: "Type Mobile",
      required: true,
      size: 12,
    },

    {
      type: "select",
      name: "status",
      size: 12,
      required: true,
      label: "Status",
      options: [
        {
          title: "Active",
          value: "true",
        },
        {
          title: "Inactive",
          value: "false",
        },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
    },

    {
      type: "select",
      name: "userType",
      size: 12,
      required: true,
      label: "Type",
      options: [
        {
          title: "Business",
          value: "business",
        },
        {
          title: "Panchayat",
          value: "panchayat",
        },
        {
          title: "Election",
          value: "election",
        },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
    },
    {
      type: "number",
      name: "downloadLimit",
      label: "Daily Download Limit",
      placeholder: "Type Daily Download Limit",
      required: false,
      size: 12,
    },

    {
      type: "text",
      name: "village",
      label: "Village",
      placeholder: "Type village",
      size: 12,
    },
    {
      type: "text",
      name: "taluka",
      label: "taluka",
      placeholder: "Type taluka",
      size: 12,
    },
    {
      type: "text",
      name: "district",
      label: "district",
      placeholder: "Type district",
      size: 12,
    },
  ];

  const tableHeaders = [
    { title: "Name", key: "name" },
    { title: "Type", key: "userType" },
    {
      title: "Mobile",
      key: "mobile",
    },
    { title: "Status", key: "status" },
    // {
    //   title: "Subscribed",
    //   key: "subscription.subscriptionName",
    //   renderRow: (row) => row?.subscription?.subscriptionName,
    // },
    { title: "Created At", key: "createdAt" },
  ];
  const AssignSubscriptionBtn = (action) => (
    <CommonAction
      onClick={() => handleAssign(action.data)}
      color="primary"
      // title="Assign Subscription"
      children={<AssignSubscriptionIcon />}
    />
  );
  const AddBusinessPanchayatAction = (action) => {
    return (
      (action.data.userType === "business" ||
        action.data.userType === "panchayat") && (
        <CommonAction
          onClick={() =>
            handleAddBusiness({
              ...action.data.profileData,
              userId: action.data._id,
            })
          }
          // title="Add Business"
          color="secondary"
          children={<BusinessIcon />}
        />
      )
    );
  };

  const AddGramPanchayatAction = (action) => {
    return (
      action.data.userType === "panchayat" && (
        <CommonAction
          onClick={() =>
            handleAddGram({
              ...action.data.profileData,
              userId: action.data._id,
            })
          }
          title="Add Panchayat frames"
          children={<PanchayatIcon />}
        />
      )
    );
  };

  const AddElectionAction = (action) => {
    return (
      action.data.userType === "election" && (
        <CommonAction
          onClick={() =>
            handleAddElection({
              ...action.data.profileData,
              userId: action.data._id,
              userType: action.data.userType,
            })
          }
          // title="Add Election"
          color="inherit"
          children={<ElectionIcon />}
        />
      )
    );
  };

  const pageProps = {
    title: "App Users",
    layout: "datatable",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["name", "mobile"],
    searchByLabel: "Name or Mobile",
    modalSize: "xs",
    sortable: true,
    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,
    tableRowActions: [
      // AssignSubscriptionBtn,
      // AddBusinessPanchayatAction,
      AddGramPanchayatAction,
      AddElectionAction,
    ],
    pageHeaderActions: [],
    onAdd: createUser,
    onEdit: updateUser,
    onDelete: deleteUser,
    totalLength: totalLength,
    getData: (e) => getAppUsers(e),
    isLoading: isLoading,
    loadingTypes: [userTypes.CREATE_USER, userTypes.UPDATE_USER],
  };

  const toggleAdd = (mode) => {
    setOpen(mode);
    if (mode === undefined) {
      setAssignData({});
    }
  };

  const handleAssign = (data) => {
    toggleAdd("Assign");
    setAssignData(data);
  };

  const handleDeleteSubsription = (data) => {
    toggleAdd("Delete Subscription");
    setAssignData(data);
  };
  const handleDeleteSubscription = (data) => {
    dispatch(
      deleteSubscription(
        {
          subscriptionId: data.subscription.subscriptionId,
          userId: assignData._id || assignData.id,
        },
        () => {
          toggleAdd();
          dispatch(getAppUsers(pagination));
        },
        []
      )
    );
  };

  const onAddNewSubscription = (data) => {
    if (open === "Assign") {
      dispatch(
        assignSubscription(
          {
            subscriptionId: data.subscriptionId,
            userId: assignData._id || assignData.id,
          },
          () => {
            toggleAdd();
            dispatch(getAppUsers(pagination));
          },
          []
        )
      );
    }
  };

  React.useEffect(() => {
    dispatch(getAllSubscriptions());
  }, []);

  const handleAddBusiness = (data) => {
    toggleAdd("business");
    setAssignData(data);
  };
  const onAddBusiness = (data) => {
    dispatch(
      submitBusinessDetails(
        data,
        () => {
          toggleAdd();
          dispatch(getAppUsers(pagination));
        },
        []
      )
    );
  };
  const businessFormData = [
    // {
    //   type: "text",
    //   name: "businessName",
    //   label: "Business Name",
    //   placeholder: "Type Business Name",
    //   size: 12,
    // },
    // {
    //   type: "email",
    //   name: "businessEmail",
    //   label: "Business Email",
    //   placeholder: "Type Business Email",
    //   size: 4,
    // },
    // {
    //   type: "mobile",
    //   name: "businessMobile",
    //   label: "Business Mobile",
    //   placeholder: "Type Business Mobile",
    //   size: 4,
    // },
    // {
    //   type: "url",
    //   name: "businessWebsite",
    //   label: "Business Website",
    //   placeholder: "Type Business Website",
    //   size: 4,
    // },
    // {
    //   type: "text",
    //   name: "businessTagLine",
    //   label: "Business Tag Line",
    //   placeholder: "Type Business Tag Line",
    //   size: 12,
    // },
    // {
    //   type: "textarea",
    //   name: "businessAddress",
    //   label: "Business Address",
    //   placeholder: "Type Business Address",
    //   size: 12,
    // },
    // {
    //   type: "file",
    //   name: "businessLogo",
    //   label: "Business Logo",
    //   placeholder: "Type Business Logo",
    //   size: 12,
    // },
    {
      type: "file",
      name: "businessFrame",
      label: "Frames",
      size: 12,
      multiple: true,
    },
  ];

  const handleAddGram = (data) => {
    toggleAdd("panchayat");
    setAssignData(data);
  };
  const gramFormData = [
    // {
    //   type: "text",
    //   name: "panchayatName",
    //   label: "Panchayat Name",
    //   placeholder: "Type panchayat name",
    //   size: 6,
    // },
    // {
    //   type: "text",
    //   name: "personName",
    //   label: "Person Name",
    //   placeholder: "Type person name",
    //   size: 6,
    // },
    // {
    //   type: "mobile",
    //   name: "panchayatMobile",
    //   label: "Panchayat Mobile",
    //   placeholder: "Type panchayat mobile",
    //   size: 4,
    // },
    // {
    //   type: "text",
    //   name: "panchayatOccupation",
    //   label: "Occupation",
    //   placeholder: "Type occupation",
    //   size: 4,
    // },
    // {
    //   type: "url",
    //   name: "panchayatWebsite",
    //   label: "Panchayat Website",
    //   placeholder: "Type panchayat website",
    //   size: 4,
    // },
    // {
    //   type: "textarea",
    //   name: "panchayatAddress",
    //   label: "Panchayat Address",
    //   placeholder: "Type panchayat address",
    //   size: 12,
    // },
    // {
    //   type: "file",
    //   name: "personImage",
    //   label: "Person Image",
    //   placeholder: "Type person image",
    //   size: 6,
    // },
    // {
    //   type: "file",
    //   name: "panchayatLogo",
    //   label: "Panchayat Logo",
    //   placeholder: "Type panchayat logo",
    //   size: 6,
    // },
    {
      type: "file",
      name: "panchayatFrame",
      label: "Frames",
      size: 12,
      multiple: true,
    },
  ];

  const onAddPanchayat = (data) => {
    dispatch(
      submitPanchayatDetails(
        data,
        () => {
          toggleAdd();
          dispatch(getAppUsers(pagination));
        },
        []
      )
    );
  };
  const handleAddElection = (data) => {
    toggleAdd("election");
    setAssignData(data);
  };

  const electionFormData = [
    // {
    //   type: "text",
    //   name: "candidateName",
    //   label: "Candidate Name",
    //   placeholder: "Type candidate name",
    //   size: 6,
    // },
    // {
    //   type: "mobile",
    //   name: "candidateMobile",
    //   label: "Candidate Mobile",
    //   placeholder: "Type candidate mobile",
    //   size: 6,
    // },
    // {
    //   type: "text",
    //   name: "candidateSymbolName",
    //   label: "Candidate Symbol Name",
    //   placeholder: "Type candidate symbol name",
    //   size: 4,
    // },
    // {
    //   type: "number",
    //   name: "candidateNumber",
    //   label: "Candidate Number",
    //   placeholder: "Type candidate number",
    //   size: 4,
    // },
    // {
    //   type: "text",
    //   name: "candidateOccupation",
    //   label: "Occupation",
    //   placeholder: "Type occupation",
    //   size: 4,
    // },
    // {
    //   type: "text",
    //   name: "candidateLocation",
    //   label: "Election Location",
    //   placeholder: "Type election location",
    //   size: 12,
    // },

    // {
    //   type: "file",
    //   name: "candidateImage",
    //   label: "Candidate Image",
    //   placeholder: "Type candidate image",
    //   size: 6,
    // },
    // {
    //   type: "file",
    //   name: "candidateSymbol",
    //   label: "Candidate Symbol",
    //   placeholder: "Type Candidate Symbol",
    //   size: 6,
    // },
    {
      type: "file",
      name: "candidateFrame",
      label: "Frames",
      size: 12,
      multiple: true,
    },
  ];

  const onAddElection = (data) => {
    dispatch(
      submitElectionDetails(
        data,
        () => {
          toggleAdd();
          dispatch(getAppUsers(pagination));
        },
        []
      )
    );
  };
  const busineesloadingType = userTypes.ADD_BUSINESS;
  const panchayatloadingType = userTypes.ADD_GRAMPANCHAYAT;
  const electionloadingType = userTypes.ADD_ELECTION;

  return (
    <>
      <AssignSubscriptionModal
        title={"Subscription"}
        open={open === "Assign"}
        onClose={() => toggleAdd()}
        mode={open}
        onDelete={(data) => {
          handleDeleteSubsription(data);
        }}
        onSubmit={(data) => onAddNewSubscription(data)}
        data={assignData}
        size={"xs"}
      />
      {businessFormData && (
        <CommonActionModal
          formData={businessFormData}
          title={""}
          open={open === "business"}
          onClose={() => toggleAdd()}
          mode={open}
          onSubmit={(e) => onAddBusiness(e)}
          data={assignData}
          size={"lg"}
          loadingType={busineesloadingType}
        />
      )}
      {gramFormData && (
        <CommonActionModal
          formData={gramFormData}
          title={""}
          open={open === "panchayat"}
          onClose={() => toggleAdd()}
          mode={open}
          onSubmit={(e) => onAddPanchayat(e)}
          data={assignData}
          size={"lg"}
          loadingType={panchayatloadingType}
        />
      )}
      {electionFormData && (
        <CommonActionModal
          formData={electionFormData}
          title={""}
          open={open === "election"}
          onClose={() => toggleAdd()}
          mode={open}
          onSubmit={(e) => onAddElection(e)}
          data={assignData}
          size={"lg"}
          loadingType={electionloadingType}
        />
      )}
      <PageCreator {...pageProps} />
    </>
  );
};

export default AppUsers;
