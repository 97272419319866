import moment from "moment";

export const DATEFORMAT = "DD/MM/YYYY";
export const DAYMONTHFORMAT = "DD/MM";

export const DATEMONTHFORMAT = "DD MMM";

export const TIMEAMPMFORMAT = "h:mm A";

export const TIME24FORMAT = "HH:mm";

const url = "https://panchayatpost.in";
export const BASEURL = url + "/adminapi";

// const url = "http://localhost:4000";
export const BASEIMAGEURL = BASEURL.replace("/adminapi", "/");

// const url = "http://118.139.165.78:4000";

// export const BASEIMAGEURL = "https://panchayatpost.in/";

export const TIMEFORMAT = "HH:mm:ss";

export const TIMEONLY = "HH:mm";

export const DATETIMEFORMAT = "DD/MM/YYYY hh:mm a";

export const TIMEZONE = "Asia/Kolkata";

export const ROOTURL = "";

export const CURRENCY = "₹";

export const MONTHSARRAY = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const WEEKARRAY = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const ROLES = [
  { title: "Admin", value: "admin" },
  { title: "Employee", value: "employee" },
];

export const FRAMEDATA = [
  { title: "Logo", value: "logo", text: "text", variableName: "businessLogo" },
  {
    title: "Mobile",
    value: "mobile",
    text: "text",
    variableName: "businessMobile",
  },
  {
    title: "Email",
    value: "email",
    text: "text",
    variableName: "businessEmail",
  },
  {
    title: "Address",
    value: "address",
    text: "text",
    variableName: "businessAddress",
  },
  {
    title: "Website",
    value: "website",
    text: "text",
    variableName: "businessWebsite",
  },
];

export const FONTFAMILY = [
  {
    title: "Baloo Bhai 2",
    style: "'Baloo Bhai 2', cursive",
    value: "BalooBhai2-Regular",
  },
  {
    title: "Hind Vadodara",
    style: "'Hind Vadodara', sans-serif",
    value: "HindVadodara-Regular",
  },
  {
    title: "Mukta Vaani",
    style: "'Mukta Vaani', sans-serif",
    value: "MuktaVaani-Regular",
  },
  {
    title: "Noto Sans Gujarati",
    style: "'Noto Sans Gujarati', sans-serif",
    value: "NotoSansGujarati-Regular",
  },
  {
    title: "Noto Serif Gujarati",
    style: "'Noto Serif Gujarati', serif",
    value: "NotoSerifGujarati-Regular",
  },
  { title: "Rasa", style: "'Rasa', serif", value: "Rasa-Regular" },
];

export const FONTSIZES = [
  { title: "Small", value: 12 },
  { title: "Medium", value: 15 },
  { title: "Large", value: 17 },
  { title: "Extra Large", value: 21 },
  { title: "2xl", value: 25 },
];

export const FONTWEIGHTS = [
  { title: "400", value: 400 },
  { title: "500", value: 500 },
  { title: "600", value: 600 },
  { title: "700", value: 700 },
];

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dateRanges = {
  Today: [moment().toDate(), moment().toDate()],
  Yesterday: [
    moment().subtract(1, "days").toDate(),
    moment().subtract(1, "days").toDate(),
  ],
  "Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
  "Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
  "This Month": [
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ],
  "Last Month": [
    moment().subtract(1, "month").startOf("month").toDate(),
    moment().subtract(1, "month").endOf("month").toDate(),
  ],
};
