import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Inputs from "../Inputs";
import {
  Grid,
  CircularProgress,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import loadingSelector from "../../../redux/selectors/loadingSelector";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.gray[700]}`,
    color: theme.palette.text.primary,
  },
  modalTitle: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    fontSize: theme.palette.fontSizes.md,
    color: theme.palette.text.primary,
  },
}));
const CommonActionModal = ({
  open,
  onClose,
  title,
  onSubmit,
  size,
  data = {},
  mode,
  formData,
  watchFields = [],
  onWatchChange = () => {},
  defaultValues,
  loadingType,
}) => {
  const methods = useForm({
    defaultValues: defaultValues,
  });
  const {
    register,
    handleSubmit,
    watch,
    errors,

    formState,
    reset,
  } = methods;

  // console.log("methods", methods);
  // const isLoading = useSelector((state) => state.util.spinner);
  const isLoading = useSelector((state) => loadingSelector(loadingType, state));
  console.log("loadingType", isLoading, loadingType);
  const [formErrors, setFormErrors] = React.useState({});

  const [fileFields, setFileFields] = React.useState();
  const [otherFields, setOtherFields] = React.useState({});
  const classes = useStyles();
  // const dispatch = useDispatch();
  const watching = watch(watchFields);
  // console.log("error", errors);
  React.useEffect(() => {
    setFormErrors(formState.errors);
  }, [formState]);
  const handleFileFieldChange = (name, file) => {
    setFileFields({
      ...fileFields,
      [name]: file,
    });
  };
  const localSubmit = (values) => {
    onSubmit({ ...data, ...values, ...fileFields, ...otherFields });
  };

  const handleOtherChange = ({ name, value }) => {
    setOtherFields({
      ...otherFields,
      [name]: value,
    });
  };

  const handleClose = () => {
    onClose();
    setFormErrors();
    reset();
  };

  React.useEffect(() => {
    onWatchChange(watching);
  }, [watching]);

  const btnRef = React.useRef();

  return (
    open && (
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        maxWidth={size || "lg"}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ padding: "5px 15px" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.modalTitle}
            >{`${mode} ${title}`}</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <form class="form-parsley" onSubmit={handleSubmit(localSubmit)}>
              <Grid container spacing={3}>
                {formData.map((item, index) => {
                  const MyInput = Inputs[item.type];
                  return (
                    mode !== item?.hideAt && (
                      <MyInput
                        {...item}
                        key={index}
                        name={item.name}
                        label={item.label}
                        placeholder={item.placeholder}
                        defaultValue={data ? data[item.name] : ""}
                        ref={register(item.rules)}
                        error={formErrors[item.name]?.message}
                        mode={mode}
                        onFileChange={handleFileFieldChange}
                        handleOtherChange={handleOtherChange}
                        disabled={
                          item.disabled ||
                          (item?.disabledCondition &&
                            item?.disabledCondition({ mode }))
                        }
                        rules={{
                          ...item.rules,
                          ...(item.required && {
                            required: {
                              value: true,
                              message: `${item.label} is required`,
                            },
                          }),
                        }}
                      />
                    )
                  );
                })}
              </Grid>

              <input type="submit" style={{ display: "none" }} ref={btnRef} />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions style={{ marginTop: 10 }}>
          <Button
            disabled={isLoading}
            variant="text"
            onClick={() => handleClose()}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              btnRef.current.click();
            }}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Loading...&nbsp;&nbsp;
                <CircularProgress color="inherit" size={15} />
              </>
            ) : mode === "add" ? (
              "Submit"
            ) : (
              "Save changes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default CommonActionModal;
