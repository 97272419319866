import authApi from "../api/authApi";
import userApi from "../api/userApi";
import { authTypes, userTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";

export const loginUser = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Login Succcess",
      errorMessage: "Failed to Login",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: authTypes.LOGIN_USER,
      payload: {
        request: {
          url: authApi.LOGIN_USER,
          method: "post",
          data: data,
        },
      },
    });
};

export const deleteUserAccount = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Login Succcess",
      errorMessage: "Failed to Login",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: authTypes.DELETE_USER_ACCOUNT,
      payload: {
        request: {
          url: authApi.DELETE_USER_ACCOUNT,
          method: "post",
          data: data,
        },
      },
    });
};
export const logoutUser = () => {
  return {
    type: authTypes.LOGOUT_USER,
  };
};

export const getUserDetails = (token) => {
  return {
    type: userTypes.GET_USER_DETAILS,
    payload: {
      request: {
        method: "get",
        url: authApi.GET_USER_DETAILS,
        // data: {token: token},
      },
    },
  };
};

export const getAppUsers = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get users",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.GET_APP_USERS,
      payload: {
        request: {
          url: userApi.GET_APP_USERS,
          method: "GET",
          params: data,
        },
      },
    });
};

export const getAdminUsers = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get users",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.GET_ADMIN_USERS,
      payload: {
        request: {
          url: userApi.GET_ADMIN_USERS,
          method: "GET",
          params: data,
        },
      },
    });
};

export const getSubscribedUsers = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get users",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.GET_SUBSCRIBED_USERS,
      payload: {
        request: {
          url: userApi.GET_SUBSCRIBED_USERS,
          method: "GET",
          params: data,
        },
      },
    });
};

export const createUser = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User created successfully",
      errorMessage: "Failed to create user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.CREATE_USER,
      payload: {
        request: {
          url: userApi.CREATE_USER,
          method: "POST",
          data: data,
        },
      },
    });
};

export const updateUser = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User updated successfully",
      errorMessage: "Failed to update user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.UPDATE_USER,
      payload: {
        request: {
          url: userApi.UPDATE_USER,
          method: "PUT",
          data: data,
        },
      },
    });
};

export const deleteUser = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User deleted successfully",
      errorMessage: "Failed to delete user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.DELETE_USER,
      payload: {
        request: {
          url: userApi.DELETE_USER,
          method: "DELETE",
          data: data,
        },
      },
    });
};

export const assignSubscription = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User created successfully",
      errorMessage: "Failed to create user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.ASSIGN_SUBSCRIPTION,
      payload: {
        request: {
          url: userApi.ASSIGN_SUBSCRIPTION,
          method: "POST",
          data: data,
        },
      },
    });
};

export const deleteSubscription = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User created successfully",
      errorMessage: "Failed to create user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.DELETE_SUBSCRIPTION,
      payload: {
        request: {
          url: userApi.DELETE_SUBSCRIPTION,
          method: "POST",
          data: data,
        },
      },
    });
};

export const submitBusinessDetails = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
    if (key === "businessLogo") {
      if (data["businessLogo"].length > 0) {
        if (Array.isArray(data.businessLogo)) {
          formData.append(key, data.businessLogo[0]);
        }
      } else {
        formData.delete("businessLogo");
      }
    }
    if (key === "businessFrame") {
      if (data["businessFrame"].length > 0) {
        data["businessFrame"].forEach((item, index) => {
          formData.append(`businessFrame`, data["businessFrame"][index]);
        });
      } else {
        formData.delete("businessFrame");
      }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User created successfully",
      errorMessage: "Failed to create user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.ADD_BUSINESS,
      payload: {
        request: {
          url: userApi.ADD_BUSINESS,
          method: "POST",
          data: formData,
        },
      },
    });
};

export const submitPanchayatDetails = (data, cb, errorCb) => {
  const formData = new FormData();

  Object.keys(data).forEach((key, i) => {
    if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
    if (key === "panchayatLogo") {
      if (data["panchayatLogo"].length > 0) {
        if (Array.isArray(data.panchayatLogo)) {
          formData.append(key, data.panchayatLogo[0]);
        }
      } else {
        formData.delete("panchayatLogo");
      }
    }
    if (key === "personImage") {
      if (data["personImage"].length > 0) {
        if (Array.isArray(data.personImage)) {
          formData.append(key, data.personImage[0]);
        }
      } else {
        formData.delete("personImage");
      }
    }
    if (key === "panchayatFrame") {
      if (data["panchayatFrame"].length > 0) {
        data["panchayatFrame"].forEach((item, index) => {
          formData.append(`panchayatFrame`, data["panchayatFrame"][index]);
        });
      } else {
        formData.delete("panchayatFrame");
      }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User created successfully",
      errorMessage: "Failed to create user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.ADD_GRAMPANCHAYAT,
      payload: {
        request: {
          url: userApi.ADD_GRAMPANCHAYAT,
          method: "POST",
          data: formData,
        },
      },
    });
};

export const submitElectionDetails = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
    if (key === "candidateSymbol") {
      if (data["candidateSymbol"].length > 0) {
        if (Array.isArray(data.candidateSymbol)) {
          formData.append(key, data.candidateSymbol[0]);
        }
      } else {
        formData.delete("candidateSymbol");
      }
    }
    if (key === "candidateImage") {
      if (data["candidateImage"].length > 0) {
        if (Array.isArray(data.candidateImage)) {
          formData.append(key, data.candidateImage[0]);
        }
      } else {
        formData.delete("candidateImage");
      }
    }
    if (key === "candidateFrame") {
      if (data["candidateFrame"].length > 0) {
        data["candidateFrame"].forEach((item, index) => {
          formData.append(`candidateFrame`, data["candidateFrame"][index]);
        });
      } else {
        formData.delete("candidateFrame");
      }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User created successfully",
      errorMessage: "Failed to create user",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.ADD_ELECTION,
      payload: {
        request: {
          url: userApi.ADD_ELECTION,
          method: "POST",
          data: formData,
        },
      },
    });
};
