import React from "react";
import * as layouts from "./layouts";

import CommonActionModal from "../Common/Modals/CommonActionModal";

import DeleteModal from "../Common/Modals/CommonDeleteModal";

import AddCommonAction from "../Common/Actions/AddCommonAction";
import EditCommonAction from "../Common/Actions/EditAction";
import DeleteCommonAction from "../Common/Actions/DeleteCommonAction";
import ImportCommonAction from "../Common/Actions/ImportCommonAction";

import { useDispatch } from "react-redux";
import PageTitle from "./PageTtitle";
import { changePage, changeRowsPage } from "../../redux/action/utilActions";

const initState = {
  openCommonModal: undefined,
  actionData: {},
};
const PageCreator = ({
  title,
  layout = "tabular",
  formData,
  tableHeaders,
  tableData,
  searchByField,
  searchByLabel,
  initialTableRows = 5,

  sortable = false,

  paginated = true,

  enableImport = false,
  importHeaders = [],
  modalSize = "md",
  importData = [],

  hideEdit,
  hideDelete,
  hideAdd,
  hideView,
  tableRowActions = [],
  pageHeaderActions = [],
  watchFields = [],
  onWatchChange = () => {},
  defaultFormValues = {},
  deleteVariableTitle = undefined,
  onAdd = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onModalClose = () => {},
  onAddModal = () => {},
  onEditModal = () => {},
  getData,
  totalLength,
  afterAddSuccess = () => {},
  afterEditSuccess = () => {},
  afterDeleteSuccess = () => {},
  isLoading = false,
  loadingTypes = [],
}) => {
  const dispatch = useDispatch();

  const Layout = layouts[layout];

  const [state, setState] = React.useState(initState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getInitData = () => {
    dispatch(getData({ page: page, limit: rowsPerPage }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(changePage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(changePage(0));
    dispatch(changeRowsPage(parseInt(event.target.value, 10)));
  };

  const handleRowActionClick = (mode, data) => {
    if (mode == "edit") {
      onEditModal(data);
    } else if (mode == "add") {
      onAddModal(data);
    }
    setState({
      ...state,
      openCommonModal: mode,
      actionData: data,
    });
  };

  const AddAction = () => {
    return (
      <AddCommonAction
        onClick={() => handleRowActionClick("add")}
        title={title}
      />
    );
  };

  const EditAction = (action) => (
    <EditCommonAction
      onClick={() => handleRowActionClick("edit", action.data)}
    />
  );

  const DeleteAction = (action) => (
    <DeleteCommonAction
      onClick={() => handleRowActionClick("delete", action.data)}
    />
  );

  const closeModal = () => {
    setState(initState);
    onModalClose();
  };

  const onModalSubmit = (values) => {
    const mode = state.openCommonModal;
    if (mode === "add") {
      dispatch(
        onAdd({ ...defaultFormValues, ...state.actionData, ...values }, () => {
          closeModal();
          afterAddSuccess && afterAddSuccess();
          getInitData();
        })
      );
    }

    if (mode === "edit") {
      dispatch(
        onEdit({ ...defaultFormValues, ...state.actionData, ...values }, () => {
          closeModal();
          getInitData();
          afterEditSuccess && afterEditSuccess();
        })
      );
    }
  };

  const onDeleteSubmit = () => {
    dispatch(
      onDelete({ ...defaultFormValues, ...state.actionData }, () => {
        closeModal();
        afterDeleteSuccess && afterDeleteSuccess();
        getInitData();
      })
    );
  };

  const rowActions = [
    ...(!hideEdit ? [EditAction] : []),
    // ...(!hideView ? [ViewAction] : []),

    ...(!hideDelete ? [DeleteAction] : []),
    ...tableRowActions,
  ];

  const tableHeaderComponents = [...pageHeaderActions];

  React.useEffect(() => {
    getInitData();
  }, [page, rowsPerPage]);
  return (
    <div>
      {title && (
        <PageTitle
          headerComponents={tableHeaderComponents}
          title={title}
          endAction={!hideAdd && AddAction}
        />
      )}

      {formData && (
        <CommonActionModal
          formData={formData}
          title={title}
          open={["add", "edit"].includes(state.openCommonModal)}
          onClose={() => closeModal()}
          mode={state.openCommonModal}
          onSubmit={(e) => onModalSubmit(e)}
          data={state.actionData}
          size={modalSize}
          watchFields={watchFields}
          onWatchChange={onWatchChange}
          // data={defaultFormValues}
          defaultValues={defaultFormValues}
          loadingType={loadingTypes}
        />
      )}

      <DeleteModal
        size="md"
        open={["delete"].includes(state.openCommonModal)}
        title={deleteVariableTitle && state?.actionData[deleteVariableTitle]}
        onClose={() => closeModal()}
        onConfirm={() => onDeleteSubmit()}
      />
      <Layout
        tableTitle={title}
        headerComponents={tableHeaderComponents}
        actions={rowActions}
        tableData={tableData}
        header={tableHeaders}
        sortable={sortable}
        paginated={paginated}
        searchByLabel={searchByLabel}
        searchByField={searchByField}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        page={page}
        setRowsPerPage={(data) => setRowsPerPage(data)}
        totalLength={totalLength}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PageCreator;
